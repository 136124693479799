import { BffApi } from '../api';
import { JobFinishStatus, FinishJobError, FinishJobInput } from '../api/bff';
import { getLogger } from '../components/logger';
import { jobManager } from '../state';
import { FileResult, JobDetails } from '../types';
import { handleProcessJobError } from './error';
import { isFinishJobErrorIsValid } from './finish-job';
import { logCDERequestsReport } from './log';
import { shouldStopApp } from './stop-app';
import { getPatientEncountersHandler } from './job-types-handlers/patient-encounters';

export const processJob = async () => {
  const jobLogger = getLogger({ scope: `job-processing-wrapper` });
  let job: JobDetails | undefined;
  try {
    if (shouldStopApp()) {
      jobManager.clearAndNotify();
      return;
    }
    job = await BffApi.takeJob();
    if (!job) {
      jobManager.clearAndNotify();
      return;
    }

    jobManager.set({ ...job });
    const { jobUUID } = jobManager.getAll();

    jobLogger.info(`CDE worker took job ${jobUUID}`);

    let finishJobPayload: FinishJobInput;

    try {
      const handleJobResult: Awaited<FileResult[]> = await getPatientEncountersHandler();

      finishJobPayload = {
        status: JobFinishStatus.SUCCESS,
        results: handleJobResult,
        jobUUID,
        vimPatientId: jobManager.get('vimPatientId'),
        refactoredFlow: true,
      };

      logCDERequestsReport({
        job,
        jobLogger,
        status: JobFinishStatus.SUCCESS,
        results: handleJobResult,
      });
    } catch (error: any) {
      const { isValid, message }: FinishJobError = isFinishJobErrorIsValid(error.message);

      finishJobPayload = {
        status: JobFinishStatus.FAIL,
        details: message,
        results: error.fileResults,
        jobUUID,
        vimPatientId: jobManager.get('vimPatientId'),
        refactoredFlow: true,
      };

      logCDERequestsReport({
        job,
        jobLogger,
        status: JobFinishStatus.FAIL,
        results: error?.fileResults,
        isErrorValid: isValid,
        originalErrorMessage: error,
        parsedErrorMessage: message,
      });
    }

    await BffApi.finishJob(finishJobPayload);
  } catch (error) {
    handleProcessJobError({ error, job });
  } finally {
    jobManager.clear();

    // eslint-disable-next-line no-unsafe-finally
    return !!job;
  }
};
