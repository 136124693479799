import { VimOsLogger } from '@getvim-os/logger';
import { DeepRequired } from '@getvim-os/types';
import { EHRResource, UpdatableEhrFields } from '@getvim/vim-os-api';
import { EHR } from '@getvim/vim-os-sdk-api';
import equal from 'fast-deep-equal';
import { getLogger } from '../../logger';
import { Subscription } from '../subscription';
import EncounterUpdateBuilder from './encounterUpdateBuilder';
import { InternalResourceUpdater } from './internalResourceUpdater';
import { getTwoLevelObjectPopulatedKeys } from './utils';

export class EhrResourceUpdater
  extends Subscription<EHR.ResourceUpdaterSubscription>
  implements EHR.IResourceUpdater
{
  private static fullCanUpdateEncounterParams: DeepRequired<EHR.CanUpdateEncounterParams> = {
    patientInstructions: {
      generalNotes: true,
    },
    plan: {
      generalNotes: true,
    },
    billingInformation: {
      procedureCodes: true,
    },
    objective: { generalNotes: true, physicalExamNotes: true },
    subjective: {
      generalNotes: true,
      historyOfPresentIllnessNotes: true,
      reviewOfSystemsNotes: true,
      chiefComplaintNotes: true,
    },
    assessment: {
      diagnosisCodes: true,
      generalNotes: true,
    },
  };

  private log: VimOsLogger;

  constructor(private internalResourceUpdater: InternalResourceUpdater) {
    super({});
    this.log = getLogger();
    let prevUpdatableFields: UpdatableEhrFields = {};
    this.internalResourceUpdater.subscribe('updatableFields', (updatableFields) => {
      if (!equal(updatableFields.encounter, prevUpdatableFields.encounter)) {
        const toDispatch: DeepRequired<EHR.CanUpdateEncounterParams> | undefined = {
          assessment: {
            diagnosisCodes: !!updatableFields.encounter?.assessments?.diagnosisCodes,
            generalNotes: !!updatableFields.encounter?.assessments?.generalNotes,
          },
          objective: {
            generalNotes: !!updatableFields.encounter?.objective?.generalNotes,
            physicalExamNotes: !!updatableFields.encounter?.objective?.physicalExamNotes,
          },
          subjective: {
            chiefComplaintNotes: !!updatableFields.encounter?.subjective?.chiefComplaintNotes,
            generalNotes: !!updatableFields.encounter?.subjective?.generalNotes,
            historyOfPresentIllnessNotes:
              !!updatableFields.encounter?.subjective?.historyOfPresentIllnessNotes,
            reviewOfSystemsNotes: !!updatableFields.encounter?.subjective?.reviewOfSystemsNotes,
          },
          patientInstructions: {
            generalNotes: !!updatableFields.encounter?.patientInstructions?.generalNotes,
          },
          plan: {
            generalNotes: !!updatableFields.encounter?.plans?.generalNotes,
          },
          billingInformation: {
            procedureCodes: !!updatableFields.encounter?.plans?.procedureCodes,
          },
        };
        this.dispatch('encounter', toDispatch);
      }
      if (!equal(updatableFields?.referral, prevUpdatableFields.referral)) {
        const toDispatch: DeepRequired<EHR.CanUpdateReferralParams> | undefined =
          updatableFields?.referral
            ? {
                basicInformation: {
                  authCode: !!updatableFields.referral.authCode,
                  endDate: !!updatableFields.referral.endDate,
                  notes: !!updatableFields.referral.notes,
                  numberOfVisits: !!updatableFields.referral.numberOfVisits,
                  priority: !!updatableFields.referral.priority,
                  reasons: !!updatableFields.referral.reasons,
                  specialty: !!updatableFields.referral.specialty,
                  startDate: !!updatableFields.referral.startDate,
                },
                conditions: {
                  diagnosis: !!updatableFields.referral.diagnosis,
                },
                procedureCodes: {
                  cpts: !!updatableFields.referral.cpts,
                },
                targetProvider: !!updatableFields.referral.targetProvider,
              }
            : undefined;
        this.dispatch('referral', toDispatch);
      }
      prevUpdatableFields = updatableFields;
    });
  }

  private updateSoapEncounter(builder: EncounterUpdateBuilder, params: EHR.UpdateEncounterParams) {
    if (params.subjective) {
      builder.updateSubjective(params.subjective);
    }
    if (params.objective) {
      builder.updateObjective(params.objective);
    }
    if (params.assessment) {
      builder.updateAssessment(params.assessment);
    }
    if (params.plan || params.billingInformation) {
      builder.updatePlan({
        generalNotes: params.plan?.generalNotes,
        procedureCodes: params.billingInformation?.procedureCodes,
      });
    }

    if (params.patientInstructions) {
      builder.updatePatientInstructions(params.patientInstructions);
    }
  }

  public updateEncounter = async (params: EHR.UpdateEncounterParams) => {
    this.logUpdateEncounter(params);
    const builder = this.internalResourceUpdater.encounterBuilder();

    this.updateSoapEncounter(builder, params);

    await builder.commit();
  };

  public updateReferral = async (params: EHR.UpdateReferralParams) => {
    const builder = this.internalResourceUpdater.referralBuilder();

    if (params.procedureCodes?.cpts?.length) {
      builder.setCpts(params.procedureCodes?.cpts);
    }

    if (params.conditions?.diagnosis?.length) {
      builder.setDiagnosis(params.conditions?.diagnosis);
    }

    if (params.basicInformation?.endDate) {
      builder.setEndDate(params.basicInformation?.endDate);
    }

    if (params.basicInformation?.startDate) {
      builder.setStartDate(params.basicInformation?.startDate);
    }

    if (params.basicInformation?.specialty) {
      builder.setSpecialty(params.basicInformation?.specialty);
    }

    if (params.targetProvider) {
      builder.setTargetProvider(params.targetProvider);
    }

    if (params.basicInformation?.reasons?.length) {
      builder.appendReasons(params.basicInformation.reasons);
    }

    if (params.basicInformation?.notes) {
      builder.appendNotes(params.basicInformation?.notes);
    }

    if (params.basicInformation?.numberOfVisits) {
      builder.setNumberOfVisits(params.basicInformation?.numberOfVisits);
    }

    if (params.basicInformation?.authCode) {
      builder.appendAuthCode(params.basicInformation?.authCode);
    }

    if (params.basicInformation?.priority) {
      builder.setPriority(params.basicInformation?.priority);
    }

    await builder.commit();
  };

  private canUpdateEncounterField<T extends EHR.UpdateEncounterField>(
    field: T,
    value: EHR.CanUpdateEncounterParams[T],
  ): EHR.CanUpdateEncounterParams[T] {
    if (!value) {
      return undefined;
    }

    switch (field) {
      case 'assessment':
        return this.canUpdateEncounterSoapField(value, 'assessments');
      case 'billingInformation':
        return this.canUpdateEncounterSoapField(value, 'plans');
      case 'plan':
        return this.canUpdateEncounterSoapField(value, 'plans');
      case 'subjective':
      case 'objective':
      case 'patientInstructions': {
        return this.canUpdateEncounterSoapField(value, field);
      }
      default:
        return undefined;
    }
  }

  private canUpdateEncounterSoapField<
    T extends EHR.UpdateEncounterField,
    F extends keyof Required<UpdatableEhrFields>[typeof EHRResource.encounter],
  >(canUpdateSubValue: NonNullable<EHR.CanUpdateEncounterParams[T]>, vimOsUpdatableKey: F) {
    return Object.entries(canUpdateSubValue).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]:
          !value ||
          !!this.internalResourceUpdater.updatableFields?.encounter?.[vimOsUpdatableKey]?.[key],
      }),
      {},
    );
  }

  private canUpdateReferralField<T extends keyof EHR.CanUpdateReferralParams>(
    field: T,
    value: EHR.CanUpdateReferralParams[T],
  ):
    | EHR.CanUpdateReferralParams['basicInformation']
    | EHR.CanUpdateReferralParams['targetProvider']
    | EHR.CanUpdateReferralParams['procedureCodes']
    | EHR.CanUpdateReferralParams['conditions']
    | undefined {
    if (!value) {
      return undefined;
    }

    switch (field) {
      case 'basicInformation':
        return Object.entries(value).reduce(
          (acc, [key, value]) => ({
            ...acc,
            [key]: !value || !!this.internalResourceUpdater.updatableFields?.referral?.[key],
          }),
          {},
        );
      case 'targetProvider': {
        return !!this.internalResourceUpdater.updatableFields?.referral?.targetProvider;
      }
      case 'procedureCodes':
        return {
          cpts: !!this.internalResourceUpdater.updatableFields?.referral?.cpts,
        };
      case 'conditions':
        return {
          diagnosis: !!this.internalResourceUpdater.updatableFields?.referral?.diagnosis,
        };
      default:
        return undefined;
    }
  }

  private logUpdateEncounter(params: EHR.UpdateEncounterParams) {
    try {
      const canUpdateRes = this.canUpdateEncounter(EhrResourceUpdater.fullCanUpdateEncounterParams);

      const updateEncounterKeys = getTwoLevelObjectPopulatedKeys(params);

      this.log.info('Updating encounter.', {
        canUpdateRes,
        updateEncounterKeys,
        updatableFields: this.internalResourceUpdater.updatableFields,
      });
    } catch (error) {
      this.log.warning('Error while logging update encounter', { error });
    }
  }

  public canUpdateEncounter = (
    params: EHR.CanUpdateEncounterParams,
  ): EHR.CanUpdateEncounterResult => {
    const result: EHR.CanUpdateEncounterResult = {
      canUpdate: true,
      details: {},
    };

    Object.entries(params).forEach(([field, value]) => {
      const canUpdate = this.canUpdateEncounterField(field as EHR.UpdateEncounterField, value);
      result.details[field] = canUpdate;
      result.canUpdate =
        result.canUpdate &&
        (typeof canUpdate === 'object'
          ? Object.values(canUpdate).every(Boolean)
          : canUpdate === true);
    });

    return result;
  };

  public canUpdateReferral = (params: EHR.CanUpdateReferralParams): EHR.CanUpdateReferralResult => {
    const result: EHR.CanUpdateReferralResult = {
      canUpdate: true,
      details: {},
    };

    Object.entries(params).forEach(([field, value]) => {
      const canUpdate = this.canUpdateReferralField(field as EHR.UpdateReferralField, value);
      result.details[field] = canUpdate;
      result.canUpdate =
        result.canUpdate &&
        (typeof canUpdate === 'object'
          ? Object.values(canUpdate).every(Boolean)
          : canUpdate === true);
    });

    return result;
  };
}
