import 'reflect-metadata';
import { ContainerModule, interfaces } from 'inversify';
import { RateLimiter } from 'limiter';
import { LoggerScopedConfig, LoggerGlobalConfig } from './configs';
import { Logger, VimOsLogger } from './logger';
import { loggerFactoryFn, LoggerFactory } from './logger-factory';
import { getRateLimiter, rateLimiterId } from './rate-limiter';
import { SYMBOLS } from './symbols';
import { ConsoleTransport, ServerTransport } from './transports';
import { LogMetadata } from './types';

export { LoggerScopedConfig, LoggerGlobalConfig } from './configs';
export { Logger, VimOsLogger } from './logger';
export { LoggerFactory } from './logger-factory';
export type { LoggerTags, LogMetadata } from './types';

const loggerContainer = new ContainerModule((bind) => {
  bind<RateLimiter>(rateLimiterId)
    .toDynamicValue(() => {
      return getRateLimiter();
    })
    .inTransientScope();
  bind<ConsoleTransport>(ConsoleTransport).toSelf().inSingletonScope();
  bind<ServerTransport>(ServerTransport).toSelf().inSingletonScope();
  bind<LoggerGlobalConfig>(LoggerGlobalConfig).toSelf().inSingletonScope();
  bind<LoggerScopedConfig>(LoggerScopedConfig).toSelf().inSingletonScope();
  bind<LoggerFactory>(LoggerFactory).toSelf().inRequestScope();
  bind<VimOsLogger>(VimOsLogger).toSelf().inTransientScope();
  bind<interfaces.SimpleFactory<Logger>>(SYMBOLS.LoggerFactoryFn).toFactory(() => loggerFactoryFn);
});

let singletonGlobalConfig: LoggerGlobalConfig | undefined;

const getGlobalConfig = () => {
  if (!singletonGlobalConfig) {
    singletonGlobalConfig = new LoggerGlobalConfig();
  }
  return singletonGlobalConfig;
};

export const getStandAloneLogger = (scope: string, metadata: Partial<LogMetadata> = {}) => {
  const globalConfig = getGlobalConfig();
  const scopedConfig = new LoggerScopedConfig();
  scopedConfig.setMetadata(metadata);
  const logger = new VimOsLogger(
    globalConfig,
    new LoggerScopedConfig(),
    new ConsoleTransport(),
    new ServerTransport(globalConfig),
    getRateLimiter(),
  );
  logger.scope = scope;
  return logger;
};

export const loadLogger = (container: interfaces.Container) => {
  container.bind(SYMBOLS.ScopedContainer).toConstantValue(container);
  container.load(loggerContainer);
};

export const resetForChildContainer = (childContainer: interfaces.Container) => {
  childContainer.bind(SYMBOLS.ScopedContainer).toConstantValue(childContainer);
  childContainer.bind<LoggerScopedConfig>(LoggerScopedConfig).toSelf().inSingletonScope();
};
